<template>
  <div>
    <a-row :gutter="24">
      <a-col :span="8" :xl="6" :xs="24" class="mb-24">

        <div style="max-height: calc(100vh - 89px);">

          <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…"
            @search="onSearch" :loading='searchLoading' style="margin-bottom: 7px;" v-model="searchTerm.value">
            <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
                fill="#111827" />
            </svg>
          </a-input-search>

          <div v-for="room in rooms" :key="room.roomId" :class="getClass(room)" style="margin: 3px;">
            <a-card>

              <a-card-meta :title="room.roomName" :description="room.lastMessage.content" @click="selectRoom(room)">
                <template slot="avatar">
                  <a-avatar src="https://via.placeholder.com/150" />
                </template>
              </a-card-meta>
            </a-card>

          </div>

        </div>
      </a-col>

      <a-col :span="14" :xs="24" :xl="14">

        <div>

          <p v-if="selectedRoomId == null">Please select a chat on the left to continue.</p>

          <Room :roomId="selectedRoomId" :messages="messages" @submitChatRoom="createResponse" />


          <div style="text-align: center; margin: auto;">

            <i class="fa fa-gear fa-spin" v-if="sendingMessage"></i>

          </div>





        </div>

      </a-col>
    </a-row>
  </div>
</template>

<script>

import { fetchDataJSON, fetchPostJSON } from '../../services/v1/services';

import Room from './Room.vue'

export default ({
  name: 'SimpleChat',
  components: {
    'Room': Room,
  },
  props: {
    rooms: {
      type: Array,
      default: [],
    },
    channelId: {
      type: String,
      default: null,
    },
    searchLoading: {
      type: Boolean,
      default: false,
    },
    onSearch: {
      type: Function,
      default: () => { },
    },
    searchTerm: {
      type: Object,
      default: {},
    },

  },
  async mounted() {
    console.log('SimpleChat mounted');

    this.$eventHub.$on("newWhatsAppMessage", async (item) => {
      console.log('new whatsapp message', item);

      if (this.selectedRoomId == null) {
        return;
      }

      // play sound

      const audio = new Audio('/audio/chime.mp3');

      const playPromise = audio.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
          // We can now safely pause video...
          audio.pause();
        })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }

      const messagesRes = await fetchDataJSON('projects/channels/' + this.$route.params.id + '/whatsapp/contacts/' + this.selectedRoomId + '/messages/list', true);

      this.messages = messagesRes.data.channel_messages;

    });
    // console.log(this.rooms);
  },
  beforedestroy() {
    this.$eventHub.$off("newWhatsAppMessage");
  },
  methods: {
    async createResponse(message) {
      console.log('createResponse');
      this.sendingMessage = true;
      console.log(message);
      const room = this.rooms.find(room => room._id === message.roomId);

      console.log(room);

      const response = await fetchPostJSON(`projects/channels/${this.channelId}/twilio/send_whatsapp`, {
        message: message.content,
        to_number: room.fromNumber,
        contact_id: this.selectedRoomId,
      }, true);

      console.log(response);



      const messagesRes = await fetchDataJSON('projects/channels/' + this.$route.params.id + '/whatsapp/contacts/' + this.selectedRoomId + '/messages/list', true);

      this.messages = messagesRes.data.channel_messages;

      this.sendingMessage = false;

    },
    getChat() {
      this.$emit('getChat');
    },
    async selectRoom(room) {
      this.$emit('selectRoom', room);

      this.selectedRoomId = room.roomId;

      const messagesRes = await fetchDataJSON('projects/channels/' + this.$route.params.id + '/whatsapp/contacts/' + this.selectedRoomId + '/messages/list', true);

      this.messages = messagesRes.data.channel_messages;

    },
    getClass(room) {
      if (room.roomId === this.selectedRoomId) {
        return 'roomcard selected';
      }
      return 'roomcard';
    }
  },
  data() {
    return {
      selectedRoomId: null,
      sendingMessage: false,
      messages: [],
    }
  },
})

</script>

<style scoped>
.selected {
  background-color: #e7e7e7;
  border: 1px solid #1890ff;
}

.roomcard {
  cursor: pointer;
}
</style>