<!-- 
	This is an empty page for layout demonstrations, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
    <div>

        <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>


        <p>This is a summary of the business.
            You can look at each section independently to understand the score of the business.
            The general information tab gives some basic information about the business including identification
            and phone number. The KYC portion will be filled in only after the KYC process has been completed
            for the SME. The Comment outlines the summary information generated by our AI and shows any gaps identified
            by the system.
        </p>



        <a-tabs default-active-key="1">

            <a-tab-pane key="1" tab="General Information" force-render>

                <a-row v-if="!loading">
                    <a-col :span="24" class="mb-24">
                        <h4 class="mb-15">Name: {{ sme.info.first_name }} {{ sme.info.last_name }}</h4>
                        <h6 class="font-regular text-muted"><b>Phone:</b> {{ sme.info.phone }}</h6>
                        <h6 class="font-regular text-muted"><b>Address: </b>{{ sme.info.address }}</h6>
                        <h6 class="font-regular text-muted"><b>ID Number:</b> {{ sme.info.id_number }}</h6>
                        <h6 class="font-regular text-muted"><b>Gender:</b> {{ sme.info.gender }}</h6>
                        <h6 class="font-regular text-muted"><b>Statement Email:</b> {{ sme.info.parse_email }}</h6>
                        <h6 class="font-regular text-muted"><b>Last Active:</b> {{ sme.info.last_active }}</h6>
                        <h6 class="font-regular text-muted"><b>Created At:</b> {{ sme.info.created_at }}</h6>
                        <h6 class="font-regular text-muted"><b>Updated At:</b> {{ sme.info.updated_at }}</h6>
                    </a-col>
                </a-row>

            </a-tab-pane>

            <a-tab-pane key="2" tab="KYC Status" force-render>

                <a-row v-if="!loading">

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">KYC Done: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="sme.kyc.kyc_done"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!sme.kyc.kyc_done"></i>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">KYC Score: </h6>
                            <span style="margin-left: 7px; font-size: 13px;">{{ sme.kyc.kyc_score }} / 10</span>
                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <progress id="file" :value="sme.kyc.kyc_score" max="10"></progress>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">KYC Passed: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="sme.kyc.kyc_passed"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!sme.kyc.kyc_passed"></i>

                        </a-col>

                    </a-row>


                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">KYC Comment: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.kyc.comment }}</span>

                        </a-col>

                    </a-row>


                </a-row>

            </a-tab-pane>

            <a-tab-pane key="3" tab="CRB Score" force-render>

                <a-row v-if="!loading">

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">CRB Done: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="sme.crb.crb_done"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!sme.crb.crb_done"></i>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">CRB Score: </h6>
                            <span style="margin-left: 7px; font-size: 13px;">{{ sme.crb.crb_score }} / 10</span>
                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <progress id="file" :value="sme.crb.crb_score" max="10"></progress>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">CRB Comment: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.crb.comment }}</span>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Highest Borrowed: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.crb.highest_borrowed_amount }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Highest Days Arrears: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.crb.highest_days_in_arrears }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Credit Score: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.crb.credit_score }} / 900</span>

                        </a-col>

                    </a-row>


                </a-row>
            </a-tab-pane>

            <a-tab-pane key="4" tab="Statements Score" force-render>

                <a-row v-if="!loading">

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Statement Done: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="sme.statement.statement_done"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!sme.statement.statement_done"></i>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Credit Limit: </h6>
                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="margin-left: 7px; font-size: 13px;">{{ sme.statement.current_credit_limit.toFixed(1) }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Loans Found: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.statement.loans_found }}</span>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Average Paid In: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.statement.average_paid_in.toFixed(1) }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Average Repayments: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.statement.average_repayments.toFixed(1) }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Analysis Date: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.statement.statement_date }}</span>

                        </a-col>

                    </a-row>


                </a-row>
            </a-tab-pane>

            <a-tab-pane key="5" tab="Production History" force-render>
                No information to display at the moment
            </a-tab-pane>

        </a-tabs>




    </div>
</template>

<script>

import Gauge from '@chrisheanan/vue-gauge';

import { register } from 'vue-advanced-chat'
register()

// "Projects" table component.
import WidgetStats from '../components/Widgets/WidgetStats';

import WidgetWhatsappChannelSetupVue from '../components/Widgets/WidgetWhatsappChannelSetup.vue';

import SimpleChat from '../components/Chat/SimpleChat';

import { fetchDataJSON, fetchPostJSON } from '../services/v1/services';

export default ({
    components: {
        WidgetStats,
        SimpleChat,
        WidgetWhatsappChannelSetupVue,
        Gauge
    },
    async mounted() {
        // ${this.$route.params.id}


        // this.$route.params.id;

        console.log(this.$route.params.id);


        const bizRes = await fetchDataJSON('aggregators/smes/' + this.$route.params.id + '/summary/view', true);

        this.sme = bizRes.data;

        this.loading = false;

    },
    beforedestroy() {

    },
    methods: {
        async onSearch() {
            this.searchLoading = true;

            this.selectedRoomId = null;

            console.log(this.searchTerm.value);



            this.searchLoading = false;

        },
        async saveChannelData() {
            this.processing = true;

            const channelRes = await fetchPostJSON('projects/channels/' + this.$route.params.id + '/configure', this.channel_data, true);

            console.log(channelRes);

            this.processing = false;
        },
        async sendChannelListenRequest() {

            if (this.channel_data.channel_type == 'voice' && this.channel_data.channel_provider == 'twilio' && (this.channel_data.account_sid == null || this.channel_data.api_key == null)) {
                this.$notification.error({
                    message: 'Error',
                    description: 'Please set up the necessary credentials first.',
                });
                return;
            }


            this.listenProcessing = true;

            console.log('sendChannelListenRequest');

            this.$eventHub.$emit("newCallDeviceRequest", {
                channel: this.$route.params.id,
                channel_data: this.channel_data,
            });


        },
    },
    data() {
        return {
            channel_data: {},
            general_stats: [],
            loading: true,
            listenProcessing: false,
            name: '',
            description: '',
            channel_type: '',
            processing: false,

            sme: null,
            searchLoading: false,

            searchTerm: {
                value: '',
            },
        }
    },
})

</script>

<style lang="scss"></style>