<template>

	<a-card :bordered="false" class="widget-1">

        <a-row>

            <a-col :span="24" :xl="12" class="mb-24">

                <a-form v-if="channel_data.channel_provider=='twilio'">
            

            <a-form-item label="Twilio Number">
                <a-input
                    v-model="channel_data.twilio_number"
                    :placeholder="'Twilio Number'"
                    :disabled="false"
                >
                </a-input>
            </a-form-item>

           

            <a-form-item label="Twilio Account SID">
                <a-input
                    v-model="channel_data.account_sid"
                    :placeholder="'Twilio Account SID'"
                    :disabled="false"
                >
                </a-input>
            </a-form-item>


            <a-form-item label="Twilio Account Token">
                <a-input
                    v-model="channel_data.twilio_token"
                    :placeholder="'Twilio Token'"
                    :disabled="false"
                >
                </a-input>
            </a-form-item>

           
        </a-form>

            </a-col>


        </a-row>
        
      
		
	</a-card>

</template>

<script>

	export default ({
		props: {
			channel_data: {
				type: Object,
				default: {},
			},
			status: {
				type: String,
				default: "success",
			},
		},
		data() {
			return {
			}
		},
	})

</script>